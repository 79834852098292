var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { attrs: { type: "flex", justify: "space-around", align: "middle" } },
    [
      _c(
        "a-col",
        {
          staticClass: "card-number",
          attrs: { xs: 24, sm: 24, md: 24, lg: 14 },
        },
        [
          _c("a-icon", { attrs: { type: "credit-card" } }),
          _vm._v("\n    **** **** **** " + _vm._s(_vm.card.last4) + "\n    "),
          _c("br"),
          _vm._v(
            "\n    " +
              _vm._s(_vm.card.exp_month) +
              "/" +
              _vm._s(_vm.card.exp_year) +
              "\n  "
          ),
        ],
        1
      ),
      _c(
        "a-col",
        {
          staticClass: "gx-text-right",
          attrs: { xs: 24, sm: 24, md: 24, lg: 10 },
        },
        [
          _vm.card.default
            ? _c(
                "div",
                { staticClass: "card-default" },
                [
                  _c(
                    "a-tag",
                    {
                      staticStyle: { "margin-right": "0" },
                      attrs: { color: "#108ee9" },
                    },
                    [_vm._v("Default")]
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.card.default
            ? _c(
                "a-button",
                {
                  staticStyle: { "margin-top": "12px" },
                  attrs: { size: "small", block: "", type: "primary" },
                  on: { click: _vm.setDefault },
                },
                [_vm._v("\n      Make Default\n    ")]
              )
            : _vm._e(),
          _vm.card.default != 1
            ? _c(
                "a-button",
                {
                  attrs: { size: "small", block: "", type: "danger" },
                  on: { click: _vm.removeCard },
                },
                [_vm._v("\n      Remove\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }